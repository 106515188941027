import { BadgeValue, InhaberEntitiesSelectors, NavigationService } from '@adnova/jf-ng-components';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/store/states/app.state';

@Component({
  selector: 'app-betriebsauswahl-page',
  templateUrl: './betriebsauswahl.page.component.html'
})
export class BetriebsauswahlPageComponent implements OnInit, OnDestroy {
  private readonly _unsubscribe$ = new Subject<void>();
  private store = inject(Store<AppState>);
  protected badgeCounts: BadgeValue[] = [];

  protected showError: boolean = true;

  ngOnInit(): void {
    this.store.select(InhaberEntitiesSelectors.isCurrentInhaberValid).pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe((isCurrentInhaberValid) => {
      this.showError = !isCurrentInhaberValid;
    });
  }



  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
